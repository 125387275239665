import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from '../assets/full2.jpg';
import bm from '../assets/bm.jpg'
import es from '../assets/es.png'
import tg from '../assets/tg.png'
import tw from '../assets/tw.png'
import db from '../assets/db.png'
import dx from '../assets/dx.png'
import td from '../assets/td.png'
import gif from '../assets/gif.gif'
import eth from '../assets/eth.png'
import ab from '../assets/about.png'
import audioFile from '../assets/audio.mp3';
import '../App.css';
import links from '../links.json';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif} alt='rock'></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth} alt='eth'></img>
        </div>
      </div>
      <body>
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <h1 className='top front bold'>ովշեմանեմ</h1>
        <div className='container front'>
          <div className='card' >
            <img src={logo} className="App-logo" alt="logo" width='500vw'/>
          </div>
        </div>
        <h1 className='top front'>HE IS REAL.</h1>
        <header className="App-header">
        <div className='container front'>
          <div className='item'>
            <a href={links.etherscan}><img alt='es' src={es} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={links.telegram}><img alt='tg' src={tg} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={links.twitter}><img alt='tg' src={tw} width='120px'/></a>
          </div>
          <div className='item'>
            <a href={links.dextools}><img alt='dx' src={dx} width='105px'/></a>
          </div>
        </div>
      </header>
        <h1 className='bottom front bold'>ովշեմանեմ, Real Pineapple Owl</h1>
        <div className='front about'>
            <a href={links.knowyourmeme} className='fairy'><img alt='ab' src={ab} width='300px'/></a>
        </div>
        <h1 className='bottom front'>HE IS ALWAYS WATCHING</h1>
        <img alt='td' src={td} width='500vw' className='front'/>
        <h1 className='bottom front'>OWSHEMANEM, only smart yellow pineapple owl</h1>
        <div className='card'>
          <img alt='db' src={bm} width='500vw' className='front App-logo'/>
        </div>
        <h1 className='top front'>զվարճալի մեմ (funy mem)</h1>
        <p className='title front'>ZERO TAX, CONTRACT RENOUNCED, LIQUIDITY BURNED FOREVER. <br/>100% OF SUPPLY SENT TO UNISWAP & LP BURNT<br/> true community token</p>
      </body>
    </div>
  );
}

export default App;
